import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {mediaLinks} from "./artists.module.css"

import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faSoundcloud, faTwitter, faInstagram, faBandcamp, faItunes, faTiktok } from '@fortawesome/free-brands-svg-icons'


const ArtistsPage = () => (
  <Layout disableFlexContainer={true}>
    <Seo title="Rabbit Hole" />
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={5}>
        <h2>
            Rabbit Hole
        </h2>
      </Grid>
      <Grid item xs={12} md={5}>
        {/* Nothing */}
      </Grid>
      <Grid item xs={12} md={5}>
        <div style={{position: 'relative', overflow: 'hidden', height: '0', paddingBottom: '56.25%'}}>        
          <iframe
          title="spotify-playlist"
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}} 
          src="https://open.spotify.com/embed/album/6vhksS9Rr4AbChsS6bru3R?utm_source=generator" width="600" height="500" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
        <div style={{marginTop: '2rem', borderBottom: 'solid 1px black'}}></div>
        <div className={mediaLinks}>
          <a href="https://facebook.com/Rabbit-Hole-102555882567423" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
          </a>
          <a href="https://www.instagram.com/rabbithole.wav/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
          </a>
        {/* <a href="https://www.tiktok.com/@rabbithole.wav" rel="noopener noreferrer" target="_blank">
        <FontAwesomeIcon icon={faTiktok}></FontAwesomeIcon>
        </a>
        
        <a href="https://soundcloud.com/rabbithole.wav" rel="noopener noreferrer" target="_blank">
        <FontAwesomeIcon icon={faSoundcloud}></FontAwesomeIcon>
        </a>
        <a href="https://twitter.com/writtenbydaria" rel="noopener noreferrer" target="_blank">
        <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
        </a>
        <a href="https://writtenbydaria.bandcamp.com/releases" rel="noopener noreferrer" target="_blank">
        <FontAwesomeIcon icon={faBandcamp}></FontAwesomeIcon>
        </a> */}
          <a href="https://music.apple.com/es/artist/rabbit-hole/621956558?l=en" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faItunes}></FontAwesomeIcon>
          </a>
        </div>
      </Grid>


      <Grid item xs={12} md={5}>
        <div style={{position: 'relative', overflow: 'hidden', height: '0', paddingBottom: '56.25%'}}>
          <iframe 
          title="youtube-video"
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
          width="560" height="315" src="https://www.youtube.com/embed/HsUHcJWKFe0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div style={{marginTop: '1rem'}}>
          <h4>
            BIO
          </h4>
          <p>
          Electronic duo based in Berlin creating ambient soundscapes.
          </p>
        </div>
      </Grid>
       
    </Grid>
  </Layout>
)

export default ArtistsPage
 